<style lang="less" scoped>
  @import "../../assets/css/variables";

  .device-detail {
    // width: 40%;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    margin-bottom: 50px;
    top: 15%;
  }

  .circle {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    margin: 0 auto;
  }
  .gray {
    background-color: #ccc;
  }
  .green {
    background-color: green;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: this.$route.path }">
        大屏设备
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container clearfix" v-on:keyup.13="getDeviceByName">
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <!-- <el-form-item>
            <el-select v-model="searchType" 
                       @change="clearSearchType"
                        placeholder="请选择">
                <el-option
                  v-for="item in [{value:1,label:'关键字'},{value:2,label:'手机号'},{value:3,label:'微信号'}]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-input
              v-if="searchType === 1"
              style="width: 364px"
              v-model="filters.key"
              clearable
              placeholder="mac地址"
            ></el-input>
            <el-input
              v-if="searchType === 2"
              style="width: 364px"
              v-model="filters.mobile"
              placeholder="手机号"
            ></el-input>
            <el-input
              v-if="searchType === 3"
              style="width: 364px"
              v-model="filters.weChat"
              placeholder="微信号"
            ></el-input>
          </el-form-item>

          <!-- <el-form-item>
            <el-input style="width: 150px" v-model.number="filters.batch" type="number" placeholder="批次"></el-input>
          </el-form-item> -->
          <!-- <el-form-item>
            <el-select clearable style="width: 150px" placeholder="状态"  v-model="filters.online">
              <el-option label="在线" :value="1"> </el-option>
              <el-option label="离线" :value="0"> </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="getDeviceByName">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-table
        :data="deviceList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>
        <el-table-column
          prop="name"
          min-width="170"
          :show-overflow-tooltip="true"
          label="设备名称"
        ></el-table-column>

        <el-table-column
          prop="mac"
          :show-overflow-tooltip="true"
          min-width="135"
          label="MAC地址"
        ></el-table-column>

        <el-table-column
          prop=""
          :show-overflow-tooltip="true"
          width="110"
          label="产品型号"
        >
          <template slot-scope="scope">
            {{ scope.row.deviceTypeCode + scope.row.productTypeCode }}
          </template>
        </el-table-column>

        <!-- <el-table-column
          v-if="isAdmin"
          prop=""
          :show-overflow-tooltip="true"
          min-width="108"
          label="品牌商"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.customerName }}
          </template>
        </el-table-column> -->

        <el-table-column
          prop="groupManageName"
          label="所属群组"
        ></el-table-column>

        <el-table-column
          width="90"
          :show-overflow-tooltip="true"
          label="联网状态"
          align="center"
        >
          <template slot-scope="scope">
            <div
              class="circle"
              :class="[scope.row.isOnline ? 'green' : 'gray']"
            ></div>
          </template>
        </el-table-column>

        <!-- <el-table-column
          prop="batch"
          width="100"
          :show-overflow-tooltip="true"
          label="批次"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.batch ? scope.row.batch : "暂无" }}
          </template>
        </el-table-column> -->

        <el-table-column
          width="160"
          :show-overflow-tooltip="true"
          label="创建时间"
          align="center"
        >
          <template slot-scope="scope">
            {{ formatTime(scope.row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="remarks"
          :show-overflow-tooltip="true"
          label="备注"
          align="center"
        ></el-table-column>

        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button size="small" @click="changeName(scope.row)">
              修改
            </el-button>

            <router-link
              :to="{
                path: '/device/info/' + scope.row.id,
                query: { searchKey: filters.key ? filters.key : '' },
              }"
            >
              <el-button type="primary" size="small" class="m-l-xs">
                详情
              </el-button>
            </router-link>

            <!-- <el-button
              :disabled="!editPermission"
              type="danger"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
              class="m-l-xs"
              >删除
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <router-link to="/device/detail">
          <!-- <el-button :disabled='!editPermission' type="primary">新增设备</el-button> -->
        </router-link>
        <!-- <el-button
          type="danger"
          :disabled="!this.sels.length > 0"
          @click="batchRemove"
          >批量删除</el-button
        > -->

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>
  </div>
</template>

<script>
  import {
    searchDevicesByKey,
    getBigDvc,
    removeDevice,
    enabledDevice,
    createDevice,
    searchProByCustomer,
    updateDvcName,
  } from "../../services/device";
  import { updateDefaultDevice } from "../../services/ucenter";
  import {
    getAllCustomers,
    searchCustomerByKey,
  } from "../../services/customer";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../config";
  // import DeviceDetail from './detail.vue';
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  let tempKey = null;

  export default {
    beforeRouteEnter(to, from, next) {
      if (from.query.searchKey) {
        tempKey = from.query.searchKey;
      } else {
        tempKey = null;
      }
      next();
    },
    data() {
      return {
        searchType: 1,
        isAdmin: false,
        isProxy: false,
        editPermission: true,
        isEdit: false,
        loaded: false,
        isLargeScreen: window.innerWidth > 1400,
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
          //sort: 'updateTime,desc'
        },
        total: 1,
        choosedRowIndex: -1, //当前选中的列，主要是在删除和修改的时候用
        deviceList: [],
        filters: {
          key: "",
          customer: null,
          mobile: null,
          weChat: null,
          batch: null,
          online: null,
        },
        deviceInfo: {
          customer: "",
          deviceKey: "",
          publicNumberproductTypeId: "",
          macAddr: "",
          sn: "",
          remarks: "",
        },
        listLoading: false,
        sels: [], //列表选中列
        authoShow: false,
        brandList: [], //品牌商列表
        brandTypeList: [], //
        batchAuthShow: false,
        // rules: {
        //   name: [
        //     {validator: valiName, trigger: 'blur'}
        //   ],
        //   code:[
        //     {validator: valiRuleCode, trigger: 'blur'}
        //   ]
        // },
      };
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
      ...mapGetters({ roles: "roles" }),
    },
    watch: {
      deviceInfo(newval) {
        // alert(1)
        // if (newval.publicNumberproductTypeId !== oldval.publicNumberproductTypeId) {
        //   this.queryBrandTypeList();
        // }
      },
    },
    methods: {
      clearSearchType() {
        this.filters.key = "";
        this.filters.mobile = null;
        this.filters.weChat = null;
      },
      /**
       * 设备编辑权限判断
       */
      submitDvc() {
        this.dvcAuthorize();
      },
      goAuthorize() {
        this.authoShow = true;
        this.queryCustomer();
      },
      /**
       * 判断登陆身份
       */
      async checkRole() {
        const role = this.user.code;
        if (role.test("admin")) {
          this.isAdmin = true;
        } else if (role.test("brands")) {
          this.isProxy = true;
          this.filters.customer = this.user.customerId;
        }
      },
      /**
       * 创建设备授权
       */
      async dvcAuthorize() {
        const dvc = this.deviceInfo;
        console.log(dvc);
        const res = await createDevice(dvc);
        console.log(res);
      },

      /**
       * 解绑授权设备
       */
      async changeName(row) {
        this.$prompt("请输入新的设备名称", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /\S+/,
          inputErrorMessage: "请输入",
        }).then(({ value }) => {
          updateDvcName(row.id, value).then((res) => {
            if (res.errorCode === 0) {
              this.queryDevices();
              Notification.success({
                title: "成功",
                message: "解绑成功",
              });
            }
          });
        });
      },

      /**
       * 获取所有品牌商
       */
      async queryCustomer(key) {
        // console.log(key)
        // console.log(this.deviceInfo.customer);
        const responseData = await searchCustomerByKey(
          { key, isProxy: true },
          this.pager
        );
        if (responseData.errorCode === 0) {
          this.brandList =
            (responseData && responseData.data && responseData.data.list) || [];
          this.queryBrandTypeList(this.deviceInfo.customer);
        }
        this.loaded = true;
        this.listLoading = false;
      },
      /**
       * 获取品牌型号列表
       */
      async queryBrandTypeList() {
        const customer = this.deviceInfo.customer;
        const responseData = await searchProByCustomer({ customer });
        if (responseData.errorCode === 0) {
          this.brandTypeList =
            (responseData && responseData.data && responseData.data.datas) ||
            [];
        }
      },
      /**
       * 设备编辑权限判断
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        this.editPermission = permissions.some((item) => {
          return item === "Device:update";
        });
      },
      formatTime(time) {
        return time ? moment(time).format("YYYY-MM-DD HH:mm:ss") : "暂无";
      },
      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeDevice(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryDevices();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 跳转到编辑
       */
      handleEdit: function(index, row) {
        this.deviceDetailVisible = true;
        this.isEdit = true;
        this.choosedRowIndex = index;
        const choosedDevice = Object.assign({}, row);
        this.deviceInfo = {
          id: choosedDevice.id,
          name: choosedDevice.name,
          contact: choosedDevice.contact,
          contactTel: choosedDevice.contactTel,
          addrProvince:
            choosedDevice.addrProvince && choosedDevice.addrProvince.id,
          isProxy: choosedDevice.isProxy,
          address: choosedDevice.address,
          remarks: choosedDevice.remarks,
        };
      },
      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeDevice(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.queryDevices();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 根据设备名筛选
       */
      getDeviceByName() {
        this.queryDevices();
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        //        this.pager.page = page;
        //        this.queryDevices();
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "list", query: { page: page } });
          this.queryDevices();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryDevices();
      },

      /**
       * 获取所有的设备
       */
      async queryDevices() {
        this.listLoading = true;
        for (let searchvalue in this.filters) {
          if (this.filters.searchvalue === "") {
            this.filters.searchvalue = null;
          }
        }
        let searchParam = Object.assign({}, this.filters);
        const res = await getBigDvc(searchParam, this.pager);
        if (res.errorCode === 0 && res.data) {
          this.deviceList = res.data.list || [];
          this.total = res.data.total;
        }
        this.loaded = true;
        this.listLoading = false;
      },
    },
    async created() {
      this.filters.key = tempKey;
      const role = this.user.role;
      if (/admin/.test(role)) {
        this.isAdmin = true;
      } else if (/brands/.test(role)) {
        this.isProxy = true;
        this.filters.customer = this.user.customerId;
      }
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }
      this.queryDevices();
      //console.log(this.isAdmin)
      // this.checkEditPermission();
    },
  };
</script>
